<script setup lang="ts">
const props = defineProps<{
  breadcrumbs: {
    name: string
    path?: string
  }[]
}>()
</script>

<template>
  <nav class="breadcrumb" aria-label="breadcrumb">
    <ol>
      <template v-for="(item, i) of props.breadcrumbs" :key="item.name">
        <li v-if="i === breadcrumbs.length - 1 && !item.path" aria-current="page">{{ item.name }}</li>
        <li v-else>
          <NuxtLink :to="item.path">{{ item.name }}</NuxtLink>
        </li>
      </template>
    </ol>
  </nav>
</template>

<style scoped lang="scss">
.breadcrumb {
  color: #8098bf;
  font-size: 16px;
  line-height: 1.4em;

  ol {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      &:not(:last-child)::after {
        display: inline-block;
        margin: 0 0.5em;
        color: #8098bf;
        content: '-';
      }

      &[aria-current="page"] {
        color: #2A72E9;
      }
    }
  }

  a {
    color: inherit;

    &:hover {
      color: #2A72E9;
      text-decoration: underline;
    }
  }
}
</style>
